const WaitToLoad4i = function () {
	let options, observer, prodScrollerJS, tabA11yStatus, isApparelItem, localizedJS, starRatingJS;
	const allRelatedContainer = document.getElementById('consolidatedProdScroller');
	const relatedContainer = document.getElementById('relatedProductsContainer');
	const detailTabs = document.getElementById('detailTabs');
	const prodCategories = document.getElementById('productCats');
	const prodTabs = document.getElementById('tabProd');
	const altItems = document.getElementById('altitemslist');
	const altRelated = document.getElementById('altrelated');
	
	const init = () => {
		starRatingJS = new StarRating4i();
		starRatingJS.Init();
		observer = new IntersectionObserver(callBack, options);
		prodScrollerJS = new prodScroller();
		localizedJS = new localizedDetails();
		isApparelItem = document.querySelector('.isApparelItem').value == "True";

		if (relatedContainer) setUpObserve(relatedContainer);
		if (detailTabs) setUpObserve(detailTabs);
		if (prodCategories) setUpObserve(prodCategories);
		if (prodTabs) setUpObserve(prodTabs);
		if (altItems) setUpObserve(altItems);
		if (altRelated) setUpObserve(altRelated);
			
		//ab test
		if(allRelatedContainer) setUpObserve(allRelatedContainer);
	},
	setUpObserve = container => {
		options = { root: container, threshold: 0.9 }; // 0.9 instead of 1 for safari fix
		observer.observe(container);
	},
 	callBack = (entries, observer) => {
		entries.forEach(function (entry) {
			if (entry.intersectionRatio > 0) {
				if (entry.target === relatedContainer) {
					getRelatedProducts(); 
					observer.unobserve(relatedContainer);
				}
				if (entry.target === detailTabs) {
					getDetailTabs(); 
					observer.unobserve(detailTabs);
				}
				if (entry.target === prodCategories) {
					getItemCategories();
					observer.unobserve(prodCategories);
				}
				if (entry.target === prodTabs) {
					getTabbedScroller();
					observer.unobserve(prodTabs);
				}
				if (entry.target === altItems) {
					getAlternativeItemsList();
					observer.unobserve(altItems);
				}
				if (entry.target === altRelated) {
					getAlternativeItems();
					observer.unobserve(altRelated);
				}
				
				//ab test
				if (entry.target === allRelatedContainer) {
					getRelated();
					observer.unobserve(allRelatedContainer);
				}
			}
		});
	},
	showHideTabs = () => {
		if (isApparelItem) document.getElementById("fitRatingTS").classList.remove("hide");
		if (localizedJS.showCustomerImages) document.getElementById("customerImagesTS").classList.remove("hide");
		if (!localizedJS.showSuccessStories) document.getElementById("successStoriesTS").classList.add("hide");
	},
	getRelatedProducts = () => {
		fetch('/productmvc/GetRelatedProductsScrollerAsync', { 
			method: 'POST', 
			body: JSON.stringify(prodID), 
			headers: { 'Content-Type': 'application/json', 'X-Requested-With': 'fetch' }
		}).then(function(response){
			return  (response.ok) ? response.text() : []; 
		}).then(function(response){
			let newDiv = document.createElement('div');
			newDiv.innerHTML = response;
			relatedContainer.appendChild(newDiv);
			relatedContainer.querySelector('.spinner').classList.add('hide');

			let tabCount = 0;
			if (document.getElementById("RelatedItems_Scroller")) {
				prodScrollerJS.Init("RelatedItems", 5, 4, 3); //numbers are # items shown per breakpoint
				tabCount++;
			}
			if (document.getElementById("SimilarItems_Scroller")) {
				prodScrollerJS.Init("SimilarItems", 5, 4, 3);
				tabCount++;
			}
			if (tabCount > 1) {
				let scrollerTabs = new tabs4i();
				scrollerTabs.init(document.getElementById("relProdScrollerTabs"));
				scrollerTabs.initTab(document.getElementById("RelatedItemsTab"));
			}
			if (tabCount == 0) relatedContainer.classList.remove('relatedProductsPlaceholder');
			starRatingJS.Init();
		}).catch(function(error){
			// place error handling here
		});
	},
	getDetailTabs = function () {
		let page = 1;
		if (window.location.href.match(/\/page\/(\d*)/) != null) page = window.location.href.match(/\/page\/(\d*)/)[1];

		let data = {
			ProductID: prodID,
			InfoType: parseInt(document.getElementById('InfoType').value),
			QID: document.getElementById('QuestionID').value,
			RFilter: document.getElementById('ReviewFilter').value,
			FitRatingFilter: document.getElementById('FitCommentFilter').value,
			Page: page
		};
			        			
		fetch('/productmvc/GetDetailsTabAsync', { 
			method: 'POST', 
			body: JSON.stringify(data), 
			headers: { 'Content-Type': 'application/json', 'X-Requested-With': 'fetch' }
		}).then(function(response){
			return  (response.ok) ? response.text() : []; 
		}).then(function(response){
			tabA11yStatus = document.getElementById("detailTabStatus") ? document.getElementById("detailTabStatus") : "";
			let newDiv = document.createElement('div');
			newDiv.innerHTML = response;
			detailTabs.appendChild(newDiv);
			
			showHideTabs();
			window.addEventListener("resize", showHideTabs);
			
			const detailTabs = new tabs4i();
			detailTabs.init(detailTabs);
			
			const customerReviews = new CustomerReviews4i();
			customerReviews.Init();

			if (isApparelItem) {
				const fitRatings = new FitRating4i();
				fitRatings.Init();
			}
	
			if (localizedJS.showCustomerImages) {
				const customerImages = new CustomerImages4i();
				customerImages.Init();
			}
			if (localizedJS.showSuccessStories){
				const successStories = new SuccessStories4i();
				successStories.Init();
			}
			detailTabs.querySelector('.spinner').classList.add('hide');
			starRatingJS.Init();
		}).catch(function(error){
			// place error handling here
		});
	},
	getItemCategories = function () {
		fetch('/productmvc/GetItemCategoriesAsync', { 
			method: 'POST', 
			body: JSON.stringify(prodID), 
			headers: { 'Content-Type': 'application/json', 'X-Requested-With': 'fetch' }
		}).then(function(response){
			return  (response.ok) ? response.text() : []; 
		}).then(function(response){
			let newDiv = document.createElement('div');
			newDiv.innerHTML = response;
			document.querySelector('.itemCats').appendChild(newDiv);
			
			document.querySelector('.itemCats').querySelector('.spinner').classList.add('hide');
			starRatingJS.Init();
		}).catch(function(error){
			// place error handling here
		});
	},
	getTabbedScroller = function () {
		fetch('/productmvc/GetTabProductScrollerAsync', { 
			method: 'POST', 
			body: JSON.stringify(prodID), 
			headers: { 'Content-Type': 'application/json', 'X-Requested-With': 'fetch' }
		}).then(function(response){
			return  (response.ok) ? response.text() : []; 
		}).then(function(response){
			let newDiv = document.createElement('div');
			newDiv.innerHTML = response;
			prodTabs.appendChild(newDiv);
			
			if (document.getElementById("MoreUpscaleItemsPanel"))
				prodScrollerJS.Init("MoreUpscaleItems", 3, 3, 2); // ID, # items xl, # items lg, # item medium ; small is always 1 so don't need to set here
			if (document.getElementById("LowerpriceItemsPanel")) 
				prodScrollerJS.Init("LowerpriceItems", 3, 3, 2);
			if (document.getElementById("LowerMinQtyItemsPanel")) 
				prodScrollerJS.Init("LowerMinQtyItems", 3, 3, 2);
			if (document.getElementById("FasterItemsPanel")) 
				prodScrollerJS.Init("FasterItems", 3, 3, 2);
				
			prodTabs.querySelector('.spinner').classList.add('hide');
			
			const scrollerTabs = new tabs4i();
			scrollerTabs.init(document.getElementById("prodScrollerTabs"));
			scrollerTabs.initTab(document.getElementById("MoreUpscaleItemsTab"));
			starRatingJS.Init();
		}).catch(function(error){
			// place error handling here
		});
	},
	getAlternativeItems = function () {
		fetch('/productmvc/GetAltRelProductsAsync', { 
			method: 'POST', 
			body: JSON.stringify(prodID), 
			headers: { 'Content-Type': 'application/json', 'X-Requested-With': 'fetch' }
		}).then(function(response){
			return  (response.ok) ? response.text() : []; //
		}).then(function(response){
			let newDiv = document.createElement('div');			
			if (response) newDiv.innerHTML = response;
			else newDiv.innerHTML = '';

			altRelated.appendChild(newDiv);
			prodScrollerJS.Init("altrelated", 5, 4, 3);
			altRelated.querySelector('.spinner').classList.add('hide');
			starRatingJS.Init();
		}).catch(function(error){
			altRelated.innerHTML = '';
			altRelated.appendChild(newDiv);
			prodScrollerJS.Init("altrelated", 5, 4, 3);
			altRelated.querySelector('.spinner').classList.add('hide');
		});
	},
	getAlternativeItemsList = function () {
		fetch('/productmvc/GetAltItemsAsync', { 
			method: 'POST', 
			body: JSON.stringify(prodID), 
			headers: { 'Content-Type': 'application/json', 'X-Requested-With': 'fetch' }
		}).then(function(response){
			return  (response.ok) ? response.text() : []; 
		}).then(function(response){
			let newDiv = document.createElement('div');
			newDiv.innerHTML = response;
			altItems.appendChild(newDiv);
			
			prodScrollerJS.Init("altitemslist", 5, 4, 3);
			altItems.querySelector('.spinner').classList.add('hide');
			starRatingJS.Init();
		}).catch(function(error){
			altItems.innerHTML = '';
		});
	},
	
	//ab test
	getRelated = () => {
	 	//todo URL
	 	fetch('/productmvc/GetTabbedProductScrollerAsync', { 
			method: 'POST', 
			body: JSON.stringify(prodID), 
			headers: { 'Content-Type': 'application/json', 'X-Requested-With': 'fetch' }
		}).then(function(response){
			return  (response.ok) ? response.text() : []; 
		}).then(function(response){
			console.log(response);
			/*let newDiv = document.createElement('div');
			newDiv.innerHTML = response;
			allRelatedContainer.appendChild(newDiv);
			allRelatedContainer.querySelector('.spinner').remove();

			//TODO
			// related, more cool promos, moreupscale, lower price, lower minimum, faster
			let tabCount = 0;
			if (document.getElementById("RelatedItems_Scroller")) {
				prodScrollerJS.Init("RelatedItems", 5, 4, 3); //numbers are # items shown per breakpoint
				tabCount++;
			}
			if (document.getElementById("SimilarItems_Scroller")) {
				prodScrollerJS.Init("SimilarItems", 5, 4, 3);
				tabCount++;
			}
			if (tabCount > 1) {
				let scrollerTabs = new tabs4i();
				scrollerTabs.init(document.getElementById("relProdScrollerTabs"));
				scrollerTabs.initTab(document.getElementById("RelatedItemsTab"));
			}
			if (tabCount == 0) allRelatedContainer.classList.remove('prodSuggestPlaceholder');
			starRatingJS.Init();*/
		}).catch(function(error){
			// place error handling here
			console.log(error);
		});
	};
	
	return{
		Init: init
	};
};