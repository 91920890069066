// holds all things shared across multiple details page files

var commonDetails = function (glide) {
    const productConfig = {
        BrandTagId: brandId,
        ReportSKU: reportSku,
        ID: prodID,
        Name: document.getElementById('ProductName').value,
        Alias: catalogAlias,
        LowestQuantity: lowestQty,
        EditID: editID,
        PriceBreak: arrBreak,
        ArrayPrice: arrPrice,
        EditQty: editQty,
        AllImprintColors: totalImprintColors,
        AllProdImgs: totalProductImages,
        IsSingleShipItem: document.getElementById("isSingleShipItem").value == "True",
        IsApparelItem: document.getElementsByClassName('isApparelItem')[0].value == "True",
        IsSuspended: document.getElementById("isSuspended") ? document.getElementById("isSuspended").value == 'True' : false,
        IsGlide: document.getElementsByClassName("glide")[0] ? true : false,
        HasNonSizingAttributes: document.getElementById('hasNonSizingAttributes').value == 'True',
        HasOptionalExtras: document.getElementById("hasOptionalExtras").value == "True",
        RecolorAvailable: document.getElementById("isRecolorable") ? document.getElementById("isRecolorable").value == 'True' : false,
        HidePricing: document.getElementById('HideAdvertisedPrice').value.toUpperCase() == 'TRUE',
        ListSelectedColors: [],
        ListImprintLocationIDs: [],
        ListProductImprintLocationIDs: [],
        ListSelectedImprintLocationName: [],
        ListSelectedColorsRGB: [],
        AdhSidesOptions: null,
        NibInkOptions: null,
        OptionalExtras: null,
        StandardProdTimeProductUrl: document.getElementById('standardProdTimeProductUrl').value,
        StandardProdTimeCatalogueAlias: document.getElementById('standardProdTimeCatalogueAlias').value,
        SteppingQty: document.getElementById('litSteppingQty') ? parseInt(document.getElementById('litSteppingQty').value) : ""
        //catAlias: document.getElementById('CatalogAlias').value,
    };
    const glideSlider = glide;
    //console.log(productConfig);
    //const forms = new forms4i();
    const helpers = new formHelpers4i();
    const localizedJS = new localizedDetails();
    const ssDetails = productConfig.IsSingleShipItem ? new singleShipDetails() : null;
    const isMobile = getComputedStyle(document.getElementById('checkIfMobile')).display == 'none';
    const isGFeed = window.location.search.indexOf("gfeed=1") != -1 ? true : false;
    const bypassImpConfig = document.querySelector(".bypassImpConfig") ? document.querySelector(".bypassImpConfig").value === "True" : false;
    const ukTaxRate = document.getElementById('UKTaxRate').value;
    const colorCapitalized = localizedJS.Color[0].toUpperCase() + localizedJS.Color.substring(1);
    const currencySymbol = document.getElementById('tdCurrencySymbol').value.replace(/\s+/g, '');
    const restrict24hrMaxQty = document.getElementById('restrict24hrMaxQty') ? document.getElementById('restrict24hrMaxQty').value === 'True' : false;
    const selBaseTrim = document.getElementById('selectedColorID');
    //const stepOne = document.querySelector('.step1');

    let processing = false;
    let configSectionLoading = false;
    let configSectionLoadingExtraChg = false;
    let exceeds24hrQtyLimit = false;
    let qty = null;
    let ExtrasTotal, prodAdhesives, prodInkColors, prodOptionalExtras;

    const waitForTabsToAppear = (activatedTabId) => {
        document.querySelector("#detailTabs").scrollIntoView();

        //allow the tabs to 'appear'
        const callback = function (mutationsList, observer) {
            for (let i = 0; i < mutationsList.length; i++) {
                if (mutationsList[i].type === 'childList') {
                    let tab = document.getElementById(activatedTabId);
                    tab.click();
                    tab.focus();
                    tabObserver.disconnect();
                }
            }
        };
        const tabObserver = new MutationObserver(callback);
        tabObserver.observe(document.getElementById('detailTabs'), {
            childList: true,
            subtree: true
        });
    };

    const accountForToMatchTrimImprintColors = function () {
        var toMatchTrimId = document.getElementById("toMatchTrimImprintColorId") ? document.getElementById("toMatchTrimImprintColorId").value : "";
        var toMatchTrimColorChips = [].slice.call(document.getElementsByClassName("colorId_" + toMatchTrimId));
        toMatchTrimColorChips.forEach(function (el) {
            el.querySelector(".colorOptionBox").style.backgroundColor = "#" + document.getElementById('selectedTrimHex').value;
        });
        var activeInputs = [].slice.call(document.getElementsByClassName("input_active"));
        activeInputs.forEach(function (el) {
            if (el.querySelector(".colorOptionBox") && el.querySelector(".colorOptionId").innerHTML == toMatchTrimId) {
                el.querySelector(".colorOptionBox").style.backgroundColor = "#" + document.getElementById('selectedTrimHex').value;
                if (document.getElementById("selectedColorID").value.split('/')[0] == document.getElementById("selectedColorID").value.split('/')[1]) {
                    var clearButtons = [].slice.call(document.getElementsByClassName("imprintClearColor"));
                    clearButtons.forEach(function (el) {
                        el.click();
                    });
                }
            }
        });
        if (document.getElementById("selectedColorID").value.split('/')[0] == document.getElementById("selectedColorID").value.split('/')[1] && document.getElementsByClassName("colorId_" + toMatchTrimId)[0]) {
            toMatchTrimColorChips = [].slice.call(document.getElementsByClassName("colorId_" + toMatchTrimId));
            toMatchTrimColorChips.forEach(function (el) {
                el.classList.add("hide");
            });
        } else if (document.getElementsByClassName("colorId_" + toMatchTrimId)[0]) {
            toMatchTrimColorChips = [].slice.call(document.getElementsByClassName("colorId_" + toMatchTrimId));
            toMatchTrimColorChips.forEach(function (el) {
                el.classList.remove("hide");
            });
        }
    };

    const validateCustomizeSections = () => {
        getSelectedImprintLocations(); // Updates productConfig.ListImprintLocationIDs
        getSelectedImprintColors();    // Updates productConfig.ListSelectedColors
        checkForReorderChanges();      // Existing functionality

        const addToCartBtn = document.getElementsByClassName('btnAddToCart')[0];
        let enableBtn = true; // Default to true but toggle based on conditions

        // Check for mobile design constraints
        if (isMobile) {
            if (document.getElementById('colorSelected').value == '') {
                addToCartBtn.classList.remove('bkgdRed');
                addToCartBtn.classList.remove('whiteLink');
                addToCartBtn.classList.add('disabledBtn');
                addToCartBtn.classList.add('bkgdMdGray');
                return false;
            }
        }

        const qtyInputs = document.getElementsByClassName('txtApparelQty');
        const qtyFilled = Array.from(qtyInputs).some(input => input.value.trim() !== '');

        const displayedColorSections = Array.from(document.getElementsByClassName("imprint-color-section"));
        displayedColorSections.forEach(function (el) {
            if (!el.classList.contains("hide") &&
                el.querySelector(".prodColorOptions").classList.contains("txtReq") &&
                !el.querySelector(".prodColorOptions").classList.contains("txtReq")) {
                enableBtn = false;
            }
        });

        if (productConfig.IsApparelItem) {
            if (document.getElementById('colorSelected').value !== '' && qtyFilled) {
                enableBtn = true;
            } else {
                enableBtn = false;
            }
        } else if (productConfig.ListImprintLocationIDs.length > 0 && productConfig.ListSelectedColors.length > 0) {
            if ((!document.getElementById("NibOptions").disabled && document.getElementById("NibOptions").options.length > 0 && document.getElementById("NibOptions").selectedIndex == 0) ||
                (!document.getElementById("adhSideOptions").disabled && document.getElementById("adhSideOptions").options.length > 0 && document.getElementById("adhSideOptions").selectedIndex == 0) ||
                (!document.getElementById("inkColorOptions").disabled && document.getElementById("inkColorOptions").options.length > 0 && document.getElementById("inkColorOptions").selectedIndex == 0) ||
                (!document.getElementById("adhOptions").disabled && document.getElementById("adhOptions").options.length > 0 && document.getElementById("adhOptions").selectedIndex == 0)) {
                enableBtn = false;
            }
        } else {
            enableBtn = false;
        }

        // Additional check: If imprint location and color are preselected
        const isColorPreselected = productConfig.ListSelectedColors.length > 0 && document.getElementById('colorSelected').value !== '';
        const isLocationPreselected = productConfig.ListImprintLocationIDs.length > 0;

        if (isColorPreselected && isLocationPreselected) {
            enableBtn = true;
        }

        // Update button state based on `enableBtn`
        if (!enableBtn) {
            addToCartBtn.classList.remove('bkgdRed');
            addToCartBtn.classList.remove('whiteLink');
            addToCartBtn.classList.add('disabledBtn');
            addToCartBtn.classList.add('bkgdMdGray');
        } else if ((enableBtn || bypassImpConfig) && !productConfig.IsSingleShipItem) {
            addToCartBtn.classList.add('bkgdRed');
            addToCartBtn.classList.remove('disabledBtn');
            addToCartBtn.classList.remove('bkgdMdGray');
            addToCartBtn.classList.add('whiteLink');
            addToCartBtn.removeAttribute("tabindex");
            addToCartBtn.removeAttribute("aria-invalid");
        } else if (enableBtn && !bypassImpConfig && productConfig.IsSingleShipItem) {
            document.querySelector("#btnConfigureSSBoxes").classList.add('bkgdBlue');
            document.querySelector("#btnConfigureSSBoxes").classList.add('whiteLink');
            document.querySelector("#btnConfigureSSBoxes").classList.remove('disabledBtn');
            document.querySelector("#btnConfigureSSBoxes").classList.remove('bkgdMdGray');
        } else if (productConfig.EditID <= 0) {
            addToCartBtn.classList.remove('bkgdRed');
            addToCartBtn.classList.remove('whiteLink');
            addToCartBtn.classList.add('disabledBtn');
            addToCartBtn.classList.add('bkgdMdGray');
        }
    };

    const calcPrice = () => {
        var TotalQty = 0;
        var apparelInputs = [].slice.call(document.querySelectorAll(".txtApparelQty"));
        //let is24hrQtyWarning = document.getElementById('qtywarning');

        apparelInputs.forEach(function (el, index, array) {
            if (el.value != '') {
                if (isNumeric(el.value)) {
                    TotalQty += parseInt(el.value);
                }
            }
        });

        if (document.getElementById('txtQty')) {
            document.getElementById('txtQty').innerHTML = TotalQty;
        }

        if ((TotalQty >= productConfig.LowestQuantity) && !exceeds24hrQtyLimit) {
            if (document.querySelector('.qtyErrorMsg')) {
                document.querySelector('.qtyErrorMsg').remove();
            }
            document.getElementById('txtQty').removeAttribute('style');
            if (document.getElementsByClassName("quantitySummary")[0]) {
                document.getElementsByClassName("quantitySummary")[0].innerHTML = getQty();
            }

            if (selBaseTrim.value != "" && document.querySelector('.step1')) {
                document.querySelector('.step1').classList.remove('disabledBtn');
                document.querySelector('.step1').classList.add('bkgdBlue');
                document.querySelector('.step1').classList.add('whiteLink');
            } else if (selBaseTrim.value != "" && bypassImpConfig) {
                document.querySelector('.btnAddToCart').classList.remove('disabledBtn');
                document.querySelector('.btnAddToCart').classList.remove('bkgdMdGray');
                document.querySelector('.btnAddToCart').classList.add('bkgdRed');
                document.querySelector('.btnAddToCart').classList.add('whiteLink');
            }

            let BreakNumBelow = -1;
            let BreakNumAbove = -1;

            for (let i = 0; i < productConfig.PriceBreak.length; i++) {
                if ((i + 1 >= productConfig.PriceBreak.length || productConfig.PriceBreak[i + 1] < 1) && TotalQty >= productConfig.PriceBreak[i] && productConfig.PriceBreak[i] > 0) {
                    BreakNumBelow = i;
                    BreakNumAbove = i;
                } else {
                    if (TotalQty >= productConfig.PriceBreak[i] && TotalQty < productConfig.PriceBreak[i + 1]) {
                        BreakNumBelow = i;
                        BreakNumAbove = i + 1;
                    }
                }
            }

            const newPrice = glideSlider.GetPrice(BreakNumBelow, TotalQty, 0);
            if (document.getElementById('lblPrice')) {
                if (document.querySelector(".glidePanel")) { //glide
                    document.getElementById('lblPrice').value = currencySymbol + newPrice.toFixed(2);
                } else { //apparel
                    document.getElementById('lblPrice').innerHTML = currencySymbol + newPrice.toFixed(2);
                }
            }

            if (document.getElementById('lblTotal')) {
                document.getElementById('lblTotal').innerHTML = currencySymbol + (newPrice * TotalQty).toFixed(2);
            }
            if (document.getElementById('lblSubtotal')) {
                document.getElementById('lblSubtotal').innerHTML = currencySymbol + (newPrice * TotalQty).toFixed(2);
            }
            if (productConfig.IsSingleShipItem && productConfig.IsApparelItem && isMobile) {
                ssDetails.FormEvents.ConfigureSSBoxesSection();
            }
            if (isMobile && productConfig.ListSelectedColors.length > 0) {
                getExtrasAmount();
            }
        } else {
            if (document.querySelector('.step1')) {
                document.querySelector('.step1').classList.add('disabledBtn');
                document.querySelector('.step1').classList.remove('bkgdBlue');
                document.querySelector('.step1').classList.remove('whiteLink');
            } else if (bypassImpConfig) {
                document.querySelector('.btnAddToCart').classList.add('disabledBtn');
                document.querySelector('.btnAddToCart').classList.remove('bkgdRed');
                document.querySelector('.btnAddToCart').classList.remove('whiteLink');
            }
            if (document.getElementById('lblPrice')) {
                if (document.querySelector(".glidePanel")) { //glide
                    document.getElementById('lblPrice').value = '';
                } else { //apparel
                    document.getElementById('lblPrice').innerHTML = '';
                }
            }
            if (document.getElementById('lblTotal')) {
                document.getElementById('lblTotal').innerHTML = '';
            }
            if (document.getElementById('lblSubtotal')) {
                document.getElementById('lblSubtotal').innerHTML = '';
            }
        }
    };

    const getQty = () => {
        if (document.getElementById("txtQty")) {
            if (!productConfig.IsApparelItem) return parseInt(document.getElementById("txtQty").value);
            else return parseInt(document.getElementById("txtQty").innerHTML);
        }
    };

    const checkForReorderChanges = function () {
        var isReorder = document.getElementById("isReorder").value.toLowerCase() === 'true';
        if (!isReorder || productConfig.EditID < 1) {
            return;
        }

        document.getElementById('reorderAlertBox').classList.add('alertWarning');
        document.getElementById('reorderAlertBoxSummary').classList.add('alertWarning');
        document.getElementById('reorderAlertBox').scrollIntoView({ behavior: "smooth" });
    };

    const formatMoney = (x) => {
        return parseFloat(x).toFixed(2);
    };

    const isNumeric = (strString) => {
        var strValidChars = "0123456789";
        var strChar;
        var blnResult = true;

        for (let i = 0; i < strString.length && blnResult == true; i++) {
            strChar = strString.charAt(i);
            if (strValidChars.indexOf(strChar) == -1) {
                blnResult = false;
            }
        }
        return blnResult;
    };

    const getSelectedImprintColors = () => {
        productConfig.ListSelectedColors = [];
        productConfig.ListSelectedColorsRGB = [];
        productConfig.ListSelectedImprintLocationName = [];
        var colorIDs = [];
        var colorRGBs = [];
        var selImprintLocs = [].slice.call(document.getElementsByClassName("selImprintLocation"));
        selImprintLocs.forEach(function (el) {
            if (!el.parentNode.parentNode.classList.contains("hide")) {
                var colorsLists = [].slice.call(el.parentNode.parentNode.getElementsByClassName('colorList'));
                colorIDs = [];
                colorRGBs = [];
                colorsLists.forEach(function (elem) {
                    var backgroundColor = elem.getElementsByClassName('selectedColor')[0].style.backgroundColor;
                    if (backgroundColor != "rgba(0, 0, 0, 0)" && backgroundColor != 'transparent') {
                        var colorID = elem.getElementsByClassName('imprintColorID')[0].value;
                        if (!(colorID === undefined || colorID == "")) colorIDs.push(colorID);
                        colorRGBs.push(backgroundColor);
                    }
                });
                if (colorIDs.length > 0) productConfig.ListSelectedColors.push(colorIDs);

                if (colorRGBs.length > 0) {
                    productConfig.ListSelectedColorsRGB.push(colorRGBs);
                    if (el[el.selectedIndex]) productConfig.ListSelectedImprintLocationName.push(el[el.selectedIndex].textContent);
                }
            }
        });
    };

    const getSelectedImprintLocations = () => {
        productConfig.ListImprintLocationIDs = [];
        productConfig.ListProductImprintLocationIDs = [];
        if (productConfig.AllImprintColors == 0) productConfig.ListSelectedImprintLocationName = [];

        var locationChexBoxes = [].slice.call(document.getElementsByClassName("selImprintLocation"));
        locationChexBoxes.forEach(function (el, index, array) {
            if (!el.parentNode.parentNode.classList.contains("hide")) {
                var inputImpLocID = el[el.selectedIndex] ? el[el.selectedIndex].value : null;
                var imprintLocationName = el[el.selectedIndex] ? el[el.selectedIndex].textContent : null;
                var impl = null;
                var prodimploc = null;
                if (productConfig.AllImprintColors == 0) {
                    productConfig.ListImprintLocationIDs.push(inputImpLocID);
                    productConfig.ListSelectedImprintLocationName.push(imprintLocationName);
                } else {
                    var colorLists = [].slice.call(el.parentNode.parentNode.getElementsByClassName('colorList'));
                    colorLists.forEach(function (el) {
                        var imprintLocationID = el.getElementsByClassName('imprintLocationID')[0].value;
                        var imprintColorID = el.getElementsByClassName('imprintColorID')[0].value;
                        var productImprintLocationID = el.getElementsByClassName('productImprintLocationID')[0].value;
                        if (imprintLocationID != undefined && imprintLocationID != "" && imprintColorID != undefined && productImprintLocationID != undefined && productImprintLocationID != "") {
                            impl = imprintLocationID;
                            prodimploc = productImprintLocationID;
                        }
                    });
                    if (impl != null) productConfig.ListImprintLocationIDs.push(impl);
                    if (prodimploc != null) productConfig.ListProductImprintLocationIDs.push(prodimploc);
                }
            }
        });
    };

    const getInkColorID = () => {
        let checkedNibColor = "";
        const inkOptions = document.getElementById("inkColorOptions");
        if (inkOptions.selectedIndex != -1) {
            checkedNibColor = inkOptions[inkOptions.selectedIndex].textContent;
        }
        let nibColorAttributeID;
        if (checkedNibColor != "" && inkOptions.value != "") {
            nibColorAttributeID = document.querySelector(".colorName" + checkedNibColor.replace(/\s/g, "").replace("/", "").replace("/", "").replace("(", "").replace(")", "")).value;
        }

        return nibColorAttributeID;
    };

    const getAdhesiveID = () => {
        let adhesiveAttributeID;
        const adhesiveSideDropdown = document.getElementById("adhSideOptions");
        const adehsiveDropdown = document.getElementById("adhOptions");
        const adhesiveInput = document.getElementById('adhesiveSelection');
        // example item: 3832-S
        // holds the real value, need to make sure a side was selected
        if (adhesiveSideDropdown.selectedIndex != -1) adhesiveAttributeID = adhesiveInput.value;
        // example item: 3832-M
        // holds the real value, need to make sure an option was selected
        if ((adhesiveAttributeID == "" || adhesiveAttributeID == undefined) && adehsiveDropdown.selectedIndex != -1) adhesiveAttributeID = adhesiveInput.value;

        return adhesiveAttributeID;
    };

    const extraChargesLoadError = () => {
        if (!bypassImpConfig) {
            document.querySelector('.extraChargesLoading').style.display = "none";
            document.getElementsByClassName('extrasResultLine')[0].insertAdjacentHTML('beforebegin', '<div role="status" class="text16 textSemiBold textDkRed">Sorry, an error has occurred. Please try again.<br/> You can also view item imprint charges in your cart.</div>');
            document.getElementsByClassName('extrasResultLine')[0].classList.add("hide");
            if (document.getElementsByClassName("setupTooltip")[0])
                document.getElementsByClassName("setupTooltip")[0].parentNode.classList.add("hide");
        } else {
            document.getElementById("loading").classList.add("hide");

            if (document.getElementById('lblBaseExtras'))
                document.getElementById('lblBaseExtras').parentNode.innerHTML = '<div role="status" class="text16 textSemiBold textDkRed">Sorry, an error has occurred. Please try again. You can also view item imprint charges in your cart.</div>';

            if (document.getElementsByClassName("setupTooltip")[0])
                document.getElementsByClassName("setupTooltip")[0].parentNode.classList.add("hide");
        }
    };

    const getExtrasAmount = (setDefaultColor) => {
        for (var i = 0; i < document.getElementsByClassName("extrasRow").length; i++) {
            if (document.getElementsByClassName("extrasRow")[i].classList.contains("hide")) {
                document.getElementsByClassName("extrasLoadingRow")[i].classList.remove("hide");
            }
        }
        if (setDefaultColor === undefined) {
            setDefaultColor = false;
        }

        var extraChgsLoadingImgs = [].slice.call(document.getElementsByClassName("extraChargesLoading"));
        extraChgsLoadingImgs.forEach(function (el) {
            el.classList.remove("hide");
        });

        var extrasResultLine = [].slice.call(document.getElementsByClassName("extrasResultLine"));
        extrasResultLine.forEach(function (el) {
            el.classList.add("hide");
        });


        ExtrasTotal = 0.00;
        var qty = getQty();

        if (!isNaN(qty)) {
            getSelectedImprintColors();
            getSelectedImprintLocations();
            if (setDefaultColor && productConfig.ListSelectedColors.length == 0 && document.getElementsByClassName("imprintColorID")[0]) {
                productConfig.ListSelectedColors.push(document.getElementsByClassName("imprintColorID")[0].value);
            }

            if ((productConfig.ListImprintLocationIDs.length == 0 || productConfig.ListProductImprintLocationIDs.length == 0 || productConfig.ListSelectedColors.length == 0) && parseInt(document.getElementsByClassName("extrasResult")[0].innerHTML) == 0) {
                if (document.querySelector(".PD_Step_2").querySelector(".errorMsg")) {
                    document.querySelector(".PD_Step_2").querySelector(".errorMsg").parentNode.removeChild(document.querySelector(".PD_Step_2").querySelector(".errorMsg"));
                }
                if (productConfig.AllImprintColors > 0) {
                    if (document.getElementsByClassName('ErrorMsgPanel')[0]) {
                        document.getElementsByClassName('ErrorMsgPanel')[0].parentNode.removeChild(document.getElementsByClassName('ErrorMsgPanel')[0]);
                    }
                    if (bypassImpConfig) {
                        document.querySelector('.stepOneHdr').insertAdjacentHTML('afterend', '<div id="ErrorBox" class="clear errorMsg ErrorMsgPanel" role="alert" aria-atomic="true"><h3 class="validationHeading">Please correct the following errors and resubmit:</h3><button id="imprintError" class="btnToHyp text16 underline marginTop10">Please select at least one imprint location and one imprint ' + localizedJS.Color + '.</button></div>');
                    } else if (document.querySelector('.imprintConfiguration')) {
                        document.querySelector('.imprintConfiguration').insertAdjacentHTML('beforebegin', '<div id="ErrorBox" class="clear errorMsg ErrorMsgPanel" role="alert" aria-atomic="true"><h3 class="validationHeading">Please correct the following errors and resubmit:</h3><button id="imprintError" class="btnToHyp text16 underline marginTop10">Please select at least one imprint location and one imprint ' + localizedJS.Color + '.</button></div>');
                    }
                    if (document.getElementById("imprintError")) {
                        document.getElementById("imprintError").focus();
                        document.getElementById("imprintError").addEventListener("click", function (e) {
                            e.preventDefault();
                            document.querySelector(".selImprintLocation").focus();
                        });
                    }
                    return false;
                }
            }

            if (productConfig.ListSelectedColors.length == 0 && parseInt(document.getElementsByClassName("extrasResult")[0].innerHTML) != 0 && document.getElementsByClassName("imprintColorID")[0]) {
                productConfig.ListSelectedColors.push(document.getElementsByClassName("imprintColorID")[0].value);
            }

            var baseTrimColor = selBaseTrim.value.split('/');
            var orderSummary = {
                ProductID: productConfig.ID,
                Quantity: qty,
                SSQuantity: productConfig.IsSingleShipItem && document.getElementById("ssBoxesToSend")[document.getElementById("ssBoxesToSend").selectedIndex].value != "" ? document.getElementById("ssBoxesToSend")[document.getElementById("ssBoxesToSend").selectedIndex].value : 0,
                ImprintColors: productConfig.ListSelectedColors,
                ImprintLocations: productConfig.ListImprintLocationIDs,
                BaseColorID: baseTrimColor[0],
                TrimColorID: baseTrimColor[1],
                EditID: productConfig.EditID,
                ProductImprintLocations: productConfig.ListProductImprintLocationIDs,
                NibColorAttributeID: getInkColorID(),
                AdhesiveAttributeID: getAdhesiveID(),
                IsReorder: document.getElementById("isReorder").value.toLowerCase() === 'true' // jag
            };
            var includeOptExtras = "";

            //if (productConfig.IsSingleShipItem && document.getElementById("SelectedOptionalExtras").value.indexOf(singleShipExtraId) == -1) {
            //includeOptExtras += ("*" + singleShipExtraId + ",");
            //}

            if (document.getElementById('SelectedOptionalExtras').value != "") {
                includeOptExtras += document.getElementById('SelectedOptionalExtras').value;
            }

            let data = {
                Summary: orderSummary,
                IncludeOptionalExtras: includeOptExtras
            };

            fetch('/productmvc/calcextrasamt', {
                method: 'POST',
                body: JSON.stringify(data),
                headers: { 'Content-Type': 'application/json', 'X-Requested-With': 'fetch', "__RequestVerificationToken": helpers.HeaderToken, 'RequestVerificationToken': helpers.HeaderToken }
            }).then(function (response) {
                return (response.ok) ? response.text() : [];
            }).then(function (response) {
                extrasSuccess(response);
            }).catch((error) => {
                if (!isGFeed) extraChargesLoadError();
                else console.error(error);
            });
        } else {
            if (isGFeed) setTimeout(() => { getExtrasAmount(setDefaultColor); }, 600);
        }
    };

    const extrasSuccess = (response) => {
    var qty = getQty();
        var extrasLoadingRow = [].slice.call(document.getElementsByClassName("extrasLoadingRow"));
        extrasLoadingRow.forEach(function (el) {
            el.classList.add("hide");
        });

        var extraChgsLoadingImgs = [].slice.call(document.getElementsByClassName("extraChargesLoading"));
        extraChgsLoadingImgs.forEach(function (el) {
            el.classList.add("hide");
        });

        var extrasResultLine = [].slice.call(document.getElementsByClassName("extrasResultLine"));
        extrasResultLine.forEach(function (el) {
            el.classList.remove("hide");
        });

        var extrasRows = [].slice.call(document.getElementsByClassName("extrasRow"));
        extrasRows.forEach(function (el) {
            if (response != 0) {
                el.classList.remove("hide");
            } else if (parseFloat(response) == 0) {
                el.classList.add("hide");
            }
        });

        var extrasResult = [].slice.call(document.getElementsByClassName("extrasResult"));
        extrasResult.forEach(function (el) {
            el.innerHTML = formatMoney(parseFloat(response));
            var extrasResult = document.getElementsByClassName("extrasResult")[0].innerHTML;
            if (extrasResult != undefined && extrasResult != "") {
                ExtrasTotal = parseFloat(extrasResult).toFixed(2);
            }
        });

        //tooltips.init(); // extras tooltips
        if (isGFeed) {
            if (document.getElementById("GFeedData")) {
                const tooltips = new toolTips();
                //tooltips.init(); //gfeed tooltip
            }
            //when bypassimpconfig is true, the page does not have the extrasResult class, so gfeed can miss the setup charges on some items
            // looks like gfeed already has the setup charge when it gets here in this scenario, removing for now to see if this causes issues pff 12/7/2023
            //if(ExtrasTotal === 0.00 && response.length > 0 && bypassImpConfig){
            //ExtrasTotal = formatMoney(response);
            //}
        }

        var total, totalDisplayed;
        if (document.getElementById("lblTotal")) {
            totalDisplayed = document.getElementById('lblTotal').innerHTML;
            totalDisplayed = totalDisplayed.replace(/\s+/g, '');
            if (totalDisplayed) {
                if (!isGFeed) {
                    total = (parseFloat(ExtrasTotal) + parseFloat(totalDisplayed.replace(totalDisplayed.substring(0, 1), '')));
                } else {
                    // Get gfeed price from model
                    var gFeedTotal = document.getElementById('gfeedTotal').value;
                    total = parseFloat(gFeedTotal);
                }
                total.toFixed(2);
            }

        }
        if (document.getElementById("lblBaseExtras")) {
            document.getElementById('lblBaseExtras').innerHTML = currencySymbol + formatMoney(parseFloat(response));
        }
//console.log('extras before', ExtrasTotal);
        if (isGFeed && typeof total !== 'undefined' && !isNaN(total) && !isNaN(ExtrasTotal)) {
            document.getElementById('currencySymbol').innerHTML = currencySymbol;
            document.getElementById('grandTotal').innerHTML = total;
            var gfeedtotal;

            if (ukTaxRate != '0') {
                //when bypassimpconfig is true, the page does not have the extrasResult class, so gfeed can miss the setup charges on some items
                //Adding this in for UK products needing ExtrasTotal - EVT 1/25/2024
                //console.log('response', response);
                if (ExtrasTotal === 0.00 && response.length > 0 && bypassImpConfig) {
                    ExtrasTotal = formatMoney(response);
                    //console.log('found extras', ExtrasTotal);
                }
                var unitPrice;
                var savedUnitPrice = false;
                if (document.querySelector(".glidePanel")) { //glide
                    unitPrice = parseFloat(document.getElementById('lblPrice').value.replace(currencySymbol, ''));
                } else { //apparel
                    unitPrice = parseFloat(document.getElementById('lblPrice').innerHTML.replace(currencySymbol, ''));
                }
                if ((unitPrice == '' || !unitPrice) && document.getElementById('GFeedUnitPrice').innerHTML) {
                    unitPrice = parseFloat(document.getElementById('GFeedUnitPrice').innerHTML.replace(currencySymbol, ''));
                    savedUnitPrice = true;
                }
                var calculatedPrice = parseFloat(unitPrice) * parseFloat(qty);
                //UK locale, add VAT 
                var extrasVat = localizedJS.VatTax(parseFloat(ExtrasTotal), 0, parseFloat(ukTaxRate));
                var totalsVat = localizedJS.VatTax(0, parseFloat(calculatedPrice), parseFloat(ukTaxRate));
                if (!savedUnitPrice) {
                    unitPrice = (parseFloat(unitPrice) + parseFloat(totalsVat / qty)).toFixed(5); //adjusted unit price including VAT
                    calculatedPrice = parseFloat(unitPrice) * parseFloat(qty); //adjusted including VAT
                }
                gfeedtotal = parseFloat(ExtrasTotal) + parseFloat(calculatedPrice) + parseFloat(extrasVat);
                //gfeedtotal = Math.round(gfeedtotal * 100) / 100;
                gfeedtotal = parseFloat(gfeedtotal).toFixed(2);
                //console.log(total);
                //document.getElementById('VatTax').innerHTML = currencySymbol + vat;
                document.getElementById('GFeedFinalPrice').innerHTML = currencySymbol + gfeedtotal;
                document.getElementById('GFeedQty').innerHTML = qty;
                    //console.log('extras', ExtrasTotal);
                    //console.log('vat extras', extrasVat);
                document.getElementById('GFeedBasePrice').innerHTML = currencySymbol + formatMoney(parseFloat(ExtrasTotal) + parseFloat(extrasVat));
                document.getElementById('GFeedUnitPrice').innerHTML = parseFloat(unitPrice).toFixed(2);// only show it to two decimals but calculate it on 5unitPrice;//
                document.getElementById('GFeedTotalPrice').innerHTML = currencySymbol + formatMoney(parseFloat(calculatedPrice));

                if (document.getElementById('GFeedPanel') && document.getElementById('GFeedPanel').style.display != 'none') {
                    document.getElementById('lblTotal').innerHTML = gfeedtotal;

                    if (document.querySelector(".glidePanel")) { //glide
                        document.getElementById('lblPrice').value = '';
                    } else { //apparel
                        document.getElementById('lblPrice').innerHTML = '';
                    }
                }
            } else {
                //Not UK
                gfeedtotal = currencySymbol + total.toFixed(2);
                if (document.getElementById('GFeedPanel') && document.getElementById('GFeedPanel').style.display != 'none') {
                    //document.getElementById('lblTotal').innerHTML = gfeedtotal;
                    if (document.querySelector(".glidePanel")) document.getElementById('lblPrice').value = '';
                    else document.getElementById('lblPrice').innerHTML = '';
                }
                if (document.getElementById('GFeedFinalPrice')) document.getElementById('GFeedFinalPrice').innerHTML = gfeedtotal;
            }
        }
        return ExtrasTotal;
    };

    const saveImprintColor = (triggerElement) => {
        var activeSection = triggerElement.closest(".imprintConfiguration");
        var selectedColor = triggerElement.querySelector('.colorOptionBox').style.backgroundColor;
        var colorTitle = triggerElement.getAttribute('title');
        var imprintLocID = triggerElement.parentNode.querySelector('.imprintLocationID').value;
        var imprintColorID = triggerElement.parentNode.querySelector('.imprintColorID').value;
        var productImprintLocationID = triggerElement.parentNode.querySelector('.productImprintLocationID').value;
        var oneColor = activeSection.querySelectorAll('.imprint-color-section').length - activeSection.querySelectorAll('.imprint-color-section.hide').length == 1;
        var selectColorOptionsElem = triggerElement.closest(".prodColorOptions");
        var needToGetExtras = false;
        if (selectColorOptionsElem.getElementsByClassName('colorList')[0].querySelector('.imprintColorBox').style.backgroundColor == "" ||
            selectColorOptionsElem.getElementsByClassName('colorList')[0].querySelector('.imprintColorBox').style.backgroundColor == "rgba(0, 0, 0, 0)") {
            needToGetExtras = true;
        }
        var colorLists = [].slice.call(selectColorOptionsElem.querySelectorAll('.colorList'));
        var emptyColorBoxes = [];
        colorLists.forEach(function (el) {
            var rgbColor = el.querySelector('.imprintColorBox').style.backgroundColor;
            if (rgbColor == "rgba(0, 0, 0, 0)" || rgbColor == "transparent" || rgbColor == "") {
                emptyColorBoxes.push(el);
            }
        });
        if (emptyColorBoxes.length > 0 && !oneColor) {
            emptyColorBoxes[0].querySelector('.imprintLocationID').value = imprintLocID;
            emptyColorBoxes[0].querySelector('.imprintColorID').value = imprintColorID;
            emptyColorBoxes[0].querySelector('.productImprintLocationID').value = productImprintLocationID;
            emptyColorBoxes[0].querySelector('.imprintColorBox').style.backgroundColor = selectedColor;
            emptyColorBoxes[0].querySelector('.imprintColorBox').setAttribute('title', colorTitle);
        } else if (colorLists.length > 0) {
            selectColorOptionsElem.getElementsByClassName('colorList')[0].querySelector('.imprintColorBox').style.backgroundColor = selectedColor;
            selectColorOptionsElem.getElementsByClassName('colorList')[0].querySelector('.imprintColorBox').setAttribute('title', colorTitle);
            selectColorOptionsElem.getElementsByClassName('colorList')[0].querySelector('.imprintLocationID').value = imprintLocID;
            selectColorOptionsElem.getElementsByClassName('colorList')[0].querySelector('.imprintColorID').value = imprintColorID;
            selectColorOptionsElem.getElementsByClassName('colorList')[0].querySelector('.productImprintLocationID').value = productImprintLocationID;
        }

        validateCustomizeSections();
        if (needToGetExtras) { // don't get extras if they're just changing colors among the same color option
            getExtrasAmount();
        }

        setTimeout(function () {
            selectColorOptionsElem.focus();
        }, 200);
    };

    const step2ScrollBehavior = (selector, scrollBehavior) => {
        if (!isMobile && !bypassImpConfig) {
            if (scrollBehavior) {
                document.getElementsByClassName('PD_Step_2')[0].scrollIntoView({ behavior: "smooth" });
            }
            if (selector.getElementsByClassName("selImprintLocation")[0] && !selector.getElementsByClassName("selImprintLocation")[0].disabled) {
                selector.getElementsByClassName("selImprintLocation")[0].focus();
            } else if (selector.getElementsByClassName("prodColorOptions")[0] && !selector.getElementsByClassName("prodColorOptions")[0].classList.contains("disabledColorSelect")) {
                selector.getElementsByClassName("prodColorOptions")[0].focus();
            } else {
                document.getElementsByClassName("btnAddToCart")[0].focus();
            }
        }
    };

    const initAttributes = (commonJS) => {
        //separate file
        prodAdhesives = new productAdhesives4i();
        prodAdhesives.Init(commonJS);

        //separate file
        prodInkColors = new productInkColors4i();
        prodInkColors.Init(commonJS);

        //separate file
        prodOptionalExtras = new optionalExtras4i();
        prodOptionalExtras.Init(commonJS);
    };

    const getAttributes = () => {
        if (productConfig.OptionalExtras == null && productConfig.HasOptionalExtras) prodOptionalExtras.GetOptions();
        if (productConfig.NibInkOptions == null && productConfig.HasNonSizingAttributes) prodInkColors.GetOptions();
        if (productConfig.AdhSidesOptions == null && productConfig.HasNonSizingAttributes) prodAdhesives.GetOptions();

        if (productConfig.IsSingleShipItem) {
            if (document.querySelector(".pricePerBox").innerHTML == "") {
                if (productConfig.IsApparelItem) {
                    ssDetails.AjaxActions.GetSingleShipExtraCharge(productConfig.LowestQuantity);
                } else {
                    ssDetails.AjaxActions.GetSingleShipExtraCharge(qty);
                }
            }
        }
    };

    const getAttributeOptions = () => {
        prodInkColors.GetOptions();
        prodAdhesives.GetOptions();
    };

    const updateRemoveColorText = function (colorSelector) { //imprint-color-section
        var colorId = colorSelector.querySelector(".input_active") && colorSelector.querySelector(".input_active").querySelector(".colorOptionId") ? colorSelector.querySelector(".input_active").querySelector(".colorOptionId").innerHTML : "";
        var colorName = "";

        // Check if colorId is not empty and is a number
        if (colorId != "" && !isNaN(colorId)) {
            // Get the color element using the colorId
            var colorElement = colorSelector.querySelector(".colorId_" + colorId);

            // Check if the color element exists
            if (colorElement) {
                // Get the color name from the color element
                colorName = colorElement.querySelector(".colorOptionDesc").innerHTML;
            }
        }

        var dropDown = colorSelector.parentNode.getElementsByClassName("selImprintLocation")[0];
        var dropDownSelection = dropDown.selectedIndex;

        if (dropDownSelection) {
            if (colorName == "") colorSelector.getElementsByClassName("imprintClearColor")[0].setAttribute("aria-label", "Remove Additional " + colorCapitalized + " from " + dropDown[dropDownSelection].textContent);
            else colorSelector.getElementsByClassName("imprintClearColor")[0].setAttribute("aria-label", "Remove " + colorCapitalized + " " + colorName.trim() + " from " + dropDown[dropDownSelection].textContent);
        }
    };

    const fadeIn = element => {
        element.classList.remove("animateFadeOut");
        element.classList.add("animateFadeIn");
        element.classList.remove("hide");
    };

    const fadeOut = element => {
        element.classList.remove("animateFadeIn");
        element.classList.add("animateFadeOut");
        setTimeout(() => {
            element.classList.add("hide");
        }, 400);
    };

    return {
        ProductConfig: productConfig,
        LocalizedJS: localizedJS,
        BypassImpConfig: bypassImpConfig,
        IsMobile: isMobile,
        IsGFeed: isGFeed,
        Restrict24hrMaxQty: restrict24hrMaxQty,
        UkTaxRate: ukTaxRate,
        SSDetails: ssDetails,
        Processing: processing,
        ConfigSectionLoading: configSectionLoading,
        ConfigSectionLoadingExtraChg: configSectionLoadingExtraChg,
        Exceeds24hrQtyLimit: exceeds24hrQtyLimit,
        ColorCapitalized: colorCapitalized,
        WaitForTabsToAppear: waitForTabsToAppear,
        AccountForToMatchTrimImprintColors: accountForToMatchTrimImprintColors,
        GetQty: getQty,
        UpdateRemoveColorText: updateRemoveColorText,
        CheckForReorderChanges: checkForReorderChanges,
        CurrencySymbol: currencySymbol,
        FormatMoney: formatMoney,
        IsNumeric: isNumeric,
        GetSelectedImprintLocations: getSelectedImprintLocations,
        GetSelectedImprintColors: getSelectedImprintColors,
        ExtraChargesLoadError: extraChargesLoadError,
        CalcPrice: calcPrice,
        GetExtrasAmount: getExtrasAmount,
        GetInkColorID: getInkColorID,
        GetAdhesiveID: getAdhesiveID,
        ValidateCustomizeSections: validateCustomizeSections,
        SaveImprintColor: saveImprintColor,
        Step2ScrollBehavior: step2ScrollBehavior,
        GetAttributes: getAttributes,
        InitAttributes: initAttributes,
        GetAttributeOptions: getAttributeOptions,
        FadeIn: fadeIn,
        FadeOut: fadeOut
    };
};